import React, { ReactElement } from 'react';
import { CarouselProps } from '@design-system/carousel';
import { ExtendableImage, ExtendableImageProps } from '@design-system/extendable-image';
import { LinkProps } from '@design-system/link';
import { ProductPageHeaderProps } from '@design-system/product-page-header';
import {
  RetailersBlockProps,
  VariantItemProps,
} from '@design-system/product-page-header/dist/cjs/models';
import uniq from 'lodash/uniq';
import { carouselMapper } from 'mappers/carousel';
import { imageMapper } from 'mappers/image';
import { linkMapper } from 'mappers/link';
import { breadcrumbsMapper } from 'mappers/pageHeader';
import { ProductPage, Retailers, SalsifyAssets } from 'templates/ProductPage/models';

import TMPLinkWrap from 'components/TMPLinkWrap';
import { nlToBr } from 'utils/stringUtils/stringUtils';

import { ProductPageHeader } from './models';

const extractFieldOptions = (
  variants: Pick<ProductPage, 'fields'>[],
  fieldsName: string[]
): VariantItemProps[] => {
  const validVariants = variants.filter((variant) => variant.fields.productData);

  const dim2Variants = fieldsName[1]
    ? uniq(validVariants.map((variant) => variant.fields.productData[fieldsName[1]]))
    : [];

  return validVariants.map((variant) => ({
    dimension1: variant.fields.productData[fieldsName[0]],
    ...(dim2Variants.length > 1 && { dimension2: variant.fields.productData[fieldsName[1]] }),
    href: variant.fields.fullPath,
  }));
};

const moveImageToBeginning = (images: SalsifyAssets[], imageId: string): SalsifyAssets[] => {
  if (!imageId) {
    return images;
  }

  return images.slice().sort(({ salsify_id: idA }, { salsify_id: idB }) => {
    if (idA === imageId) return -1;
    if (idB === imageId) return 1;

    return 0;
  });
};

const retailersMapper = (retailers: Retailers): RetailersBlockProps => ({
  label: retailers.label,
  retailerLinks: retailers.items.map((item) => {
    const image = imageMapper(item.image) as ExtendableImageProps;

    return {
      ...linkMapper(item.link),
      variant: 'retailer',
      children: <ExtendableImage {...image} />,
    } as LinkProps;
  }),
});

const imageElementsMapper = (images: SalsifyAssets[]): ReactElement[] =>
  images.map((image, index) => (
    <ExtendableImage
      key={image.salsify_id}
      {...(imageMapper(
        {
          image: {
            gatsbyImageData: image.localAsset.childImageSharp?.gatsbyImageData,
            url: image.salsify_url,
          },
          alt: image.Alt_Text,
        },
        index === 0
      ) as ExtendableImageProps)}
    />
  ));

export const productPageHeaderMapper = ({
  variant_selector,
  gallery_ui,
  breadcrumbs_ui: [
    { hide_last_item: hideLastItem, navigation_name: navigationName, overflow_button },
  ],
  fields,
  disclaimer,
  retailers,
}: ProductPageHeader): ProductPageHeaderProps => {
  const {
    productData: {
      Description_Short,
      Product_Title,
      salsify_digital_assets,
      Hero_Image,
      Aroma_Scent,
      Net_Content_Statement,
    },
    breadcrumbs,
  } = fields;

  return {
    breadcrumbs: {
      navigationName,
      hideLastItem,
      items: breadcrumbsMapper(breadcrumbs),
      overflowBtn: {
        children: overflow_button,
      },
      linkComponent: TMPLinkWrap,
    },
    description: nlToBr(Description_Short),
    ...(disclaimer && { disclaimer }),
    heading: Product_Title || '',
    ...(retailers[0] && { retailersBlock: retailersMapper(retailers[0]) }),
    carouselBlock: {
      ...carouselMapper<Omit<CarouselProps, 'items'>>(gallery_ui[0]),
      imageElements: imageElementsMapper(moveImageToBeginning(salsify_digital_assets, Hero_Image)),
    },
    subHeading: Aroma_Scent,
    ...(variant_selector[0]?.variants.length > 0 && {
      variantSelector: {
        currentDimension1: Net_Content_Statement,
        currentDimension2: Aroma_Scent,
        labelDimension1: variant_selector[0].labels[0].label_dimension_1,
        labelDimension2: variant_selector[0].labels[0].label_dimension_2,
        variants: extractFieldOptions(variant_selector[0].variants, [
          'Net_Content_Statement',
          'Aroma_Scent',
        ]),
      },
    }),
  };
};

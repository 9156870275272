import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { ProductPageHeader } from '@design-system/product-page-header';
import { productPageHeaderMapper } from 'mappers/productPageHeader';

import { Layout } from 'layout/Layout';
import BodyRender from 'components/BodyRender';
import withContentstackPreview from 'utils/livePreview';

import { ProductPageData } from './models';

const ProductPage: FC<ProductPageData> = ({
  data: {
    contentstackHarpicProductPage,
    contentstackHarpicHeader,
    contentstackHarpicFooter,
    contentstackHarpicSettings,
  },
  pageContext: { uid, locale, title },
}) => {
  if (typeof window === 'undefined') console.info('Building:', { uid, locale, title });
  const {
    seo,
    url,
    body,
    fields: { productData },
  } = contentstackHarpicProductPage;

  return (
    <Layout
      {...{
        seo: {
          ...seo,
          pageType: 'product',
          seoImage: productData?.heroImage?.url,
        },
        url,
        header: contentstackHarpicHeader,
        footer: contentstackHarpicFooter,
        pageUid: uid,
        settings: contentstackHarpicSettings,
        schemaImageUrl: seo.open_graph.image?.url || productData?.heroImage?.url,
        additionalSchema: 'Product',
      }}
    >
      {productData ? (
        <ProductPageHeader {...productPageHeaderMapper(contentstackHarpicProductPage)} />
      ) : null}
      <BodyRender
        items={body}
        externalData={{ salsifyExternalData: productData }}
        currentPageUid={uid}
      />
    </Layout>
  );
};

export const query = graphql`
  query ProductPage($id: String, $locale: String) {
    contentstackHarpicProductPage(id: { eq: $id }, publish_details: { locale: { eq: $locale } }) {
      seo {
        ...SeoFragment
      }
      url
      body {
        ...BodyFragment
      }
      ...ProductPageFragment
      variant_selector {
        variants {
          ...ProductPageFragment
        }
      }
    }
    contentstackHarpicHeader(publish_details: { locale: { eq: $locale } }) {
      ...HeaderFragment
    }
    contentstackHarpicFooter(publish_details: { locale: { eq: $locale } }) {
      ...FooterFragment
    }
    contentstackHarpicSettings {
      ...SettingsFragment
    }
  }
`;

export default withContentstackPreview(ProductPage, 'productpage');
